@import './variables';
/* You can add global styles to this file, and also import other style files */

:root {
  color-sheme: light dark;
  --bg-color: #{$color-white};
  --text-color: #{$color-black};
  --border-color: #{$color-mischka};
  --input-color-error: #{$color-burnt-sienna};
  --input-color-disabled: #{$color-manatee};
}

body {
  margin: 0;
  background: var(--bg-color);
  color: var(--text-color);
  font-family: 'SF Pro Text', sans-serif;
  touch-action: manipulation;
}

ion-button::part(native) {
  touch-action: manipulation;
}

.about-own-id {
  margin-bottom: 4vh;
}

.own-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uni-icon {
  display: block;
  width: 132px;
  height: 132px;
  margin: 0 auto;
}

.own-big-icon {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.ios {
  .own-big-icon {
    width: 140px;
    height: 140px;
  }

  .about-own-id {
    margin-bottom: 28px;
  }
}

.own-list {

  ion-note {
    &.md {
      font-size: 16px;
      padding-top: 15px;
    }
  }

  ion-item {
    &:first-child::before {
      content: '';
      display: block;
      height: 0;
      border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    }

    &.ios {
      &:first-child::before {
        margin-left: 20px;
        border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));;
      }

        &.ion-invalid {
        &.ion-touched,
        &.ion-dirty {
          --border-color: var(--highlight-background);
        }
      }

      &.item-has-focus {
        --border-color: var(--highlight-color-focused);
      }
    }
  }
}

.or {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666;

  margin: 24px 0;

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    background: #666;
    height: 1px;
    width: calc(50% - 24px);

    bottom: 11px
  }

  &:after {
    right: 0;
  }
}

[set-focus] {
  outline: none;
}

.own-field {
  margin: 0 0 12px;
}

.own-field-checkbox {
  margin: 0;
}

.own-label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #666666;
  margin-bottom: 4px;
}

.own-input {
  height: 46px;

  background: #FFFFFF;
  border: 1px solid #BABABA;
  border-radius: 5px;
  padding: 12px 10px;
  color: #666666;
  width: 100%;
  appearance: none;

  &.own-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
  }

  &::placeholder {
    color: #B4B4B4;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  & + .own-label-date {
    position: absolute;
    height: 44px;
    background: white;
    line-height: 44px;
    top: 1px;
    left: 1px;
    border-radius: 5px;
    padding: 0 10px;
    color: #bbb;
    font-size: 16px;
    width: calc(100% - 22px);
  }
}

.own-errors {
  font-size: 13px;
  line-height: 16px;
  color: #EB4D3D;
  margin-top: 6px;
}

.own-spacer {
  @for $i from 1 through 5 {
    &-#{$i * 10} {
      margin-top: #{$i * 10}px;
      display: block;
    }
  }
}

ion-note {
  --color: #737373;
}
